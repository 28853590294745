import fetchSubscriptionsSource from '@comparacar/lib/src/api/comparacar/subscriptionsSource'
import { selector, atom, selectorFamily } from 'recoil'

import engineState from './engine'

const brandsList: Partial<Provider>[] = [
  { slug: 'mercadolivre', name: 'MercadoLivre', label: 'Mercadolivre' },
  { slug: 'icarros', name: 'ICarros', label: 'ICarros' },
  { slug: 'mobiauto', name: 'Mobiauto', label: 'Mobiauto' },
  { slug: 'meucarronovo', name: 'MeuCarroNovo', label: 'Meucarronovo' },
  { slug: 'usadosbr', name: 'UsadosBR', label: 'UsadosBR' }
]

export const fetchProviderState = atom<Provider[]>({
  key: 'fetchProviderState',
  default: selector({
    key: 'fetchProviderState/default',
    get: async () => {
      const subscriptionProvider = (await fetchSubscriptionsSource()).map<Provider>(item => ({
        ...item,
        type: 'subscribe'
      }))
      return [...subscriptionProvider]
    }
  })
})

export const carouselIconsState = selector<Partial<Provider & { href: string }>[]>({
  key: 'carouselIconsState',
  get: ({ get }) => {
    const providers = get(fetchProviderState)
    return providers
      .filter(item => item.type === 'subscribe')
      .map(item => ({
        slug: item.slug,
        href: `/assinar/carro?source=${item.name}`
      }))
  }
})

export const getProvider = selectorFamily<Provider, string>({
  key: 'getProvider',
  get:
    source =>
    ({ get }) =>
      get(fetchProviderState).find(item => item.slug === source || item.name === source)
})
